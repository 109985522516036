<div class="navbar">
  <div class="left-space"></div>
  <h1 class="navbar__title">Q U I Z Z O</h1>
  <ul class="navbar__links">
    <li>
      <a
        class="navbar__links__link"
        routerLink="/pick-a-team"
        routerLinkActive="navbar__links__link--active"
        >Pick a Team</a
      >
    </li>

    <li>
      <a
        routerLink="/home"
        routerLinkActive="navbar__links__link--active"
        class="navbar__links__link"
        >Dashboard</a
      >
    </li>

    <li>
      <a
        routerLink="/logout"
        routerLinkActive="navbar__links__link--active"
        class="navbar__links__link"
        >Logout</a
      >
    </li>
  </ul>
  <div class="navbar__mobile">
    <svg
      fill="white"
      viewBox="0 0 100 80"
      width="40"
      height="40"
      (click)="onClickMobile()"
    >
      <rect width="100" height="20" rx="8"></rect>
      <rect y="30" width="100" height="20" rx="8"></rect>
      <rect y="60" width="100" height="20" rx="8"></rect>
    </svg>
  </div>
</div>
<app-mobile-nav
  (isClosed)="onClickMobile()"
  [isActive]="mobileIsActive"
></app-mobile-nav>
