<div class="history">
  <ng-container *ngIf="team">
    <h1 class="history__title">
      Previous Games
    </h1>
    <div
      class="history__game"
      *ngFor="let game of getSortedGames(team.games); let indexOfGame = index"
      (click)="onClickGame(indexOfGame)"
    >
      <div class="history__game__total-container">
        <h3 class="history__game__total-container__date">
          {{ parseDate(game.date) }}
        </h3>
        <h3 class="history__game__total-container__points">
          {{ getTotalPoints(game) }}
        </h3>
      </div>
      <div class="history__game__rounds-container" [id]="'game-' + indexOfGame">
        <div
          class="history__game__rounds-container__round"
          *ngFor="let round of game.rounds; let indexOfRound = index"
        >
          <h3 class="history__game__rounds-container__round__round-title">
            Round {{ indexOfRound + 1 }}
            <div
              *ngIf="round.isJoker"
              class="history__game__rounds-container__round__round-title__joker-container"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#0071b9"
                  [attr.fill]="'gold'"
                  stroke-width="2"
                  d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828
              1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828
              8.332-1.151z"
                />
              </svg>
            </div>
          </h3>
          <h3 class="history__game__rounds-container__round__round-score">
            {{ round.score }}
          </h3>
        </div>
      </div>
    </div>
  </ng-container>
</div>
