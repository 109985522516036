import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-team-card-name-edit",
  templateUrl: "./team-card-name-edit.component.html",
  styleUrls: ["./team-card-name-edit.component.scss"],
})
export class TeamCardNameEditComponent implements OnInit {
  @ViewChild("input") input: ElementRef;

  @Input()
  value;
  @Input()
  indexOfScore;
  isBeingEdited;
  @Input()
  inputType = "text";
  @Output()
  nameEmitted = new EventEmitter();
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  onEdit() {
    this.isBeingEdited = !this.isBeingEdited;
    if (this.isBeingEdited) {
      setTimeout(() => {
        const element = this.renderer.selectRootElement(
          `#input-${this.indexOfScore}`
        );
        element.focus();
      }, 50);
    }
  }
  finalizeEvent(event) {
    event.preventDefault();

    const {
      target: { value: roundValue },
    } = event;
    this.isBeingEdited = false;
    if (roundValue) {
      this.emitValue(roundValue);
    }
  }

  emitValue(score) {
    this.nameEmitted.emit({ name: score });
  }
  onKey(event) {
    this.input.nativeElement.blur();
  }
  onBlur(event) {
    this.finalizeEvent(event);
  }
}
