import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class QuizzoService {
  endpoint = "https://sjbxhj5fdl.execute-api.us-east-1.amazonaws.com/dev/teams";
  updateTeamEndpoint =
    "https://sjbxhj5fdl.execute-api.us-east-1.amazonaws.com/dev/teams/updateTeam";
  constructor(private http: HttpClient) {}
  getTeams() {
    return this.http.get(this.endpoint);
  }
  updateTeam(updatedTeam) {
    return this.http.post(this.updateTeamEndpoint, updatedTeam);
  }
}
