import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-mobile-nav",
  templateUrl: "./mobile-nav.component.html",
  styleUrls: ["./mobile-nav.component.scss"],
})
export class MobileNavComponent implements OnInit {
  @Input()
  isActive;
  @Output()
  isClosed = new EventEmitter();
  @Input()
  hamburgerIsDark;
  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.isClosed.emit();
  }

  public onClick(elementId: string): void {
    // const elemNode = document.querySelector(`#${elementId}`);
    // scrollIntoView(elemNode, {
    //   behavior: "smooth",
    //   scrollMode: "if-needed",
    // }).then((isDoneScrolling) => {
    //   this.isClosed.emit();
    // });
    // setTimeout(() => {
    //   this.isClosed.emit();
    // }, 500);
  }
}
