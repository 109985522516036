import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-best-of-three",
  templateUrl: "./best-of-three.component.html",
  styleUrls: ["./best-of-three.component.scss"],
})
export class BestOfThreeComponent implements OnInit {
  constructor() {}

  @Input()
  teams;
  sortedTeams;
  ngOnInit(): void {
    this.sortedTeams = this.getTeamGamesSorted();
  }

  getTeamGamesSorted() {
    const sortedGamesWithTeamName = this.teams.map((team) => {
      let sortedGames = team.games.sort((gameOne, gameTwo) => {
        const gameOneRoundSum = gameOne.rounds.reduce(
          (a, { score }) => a + score,
          0
        );
        const gameTwoRoundSum = gameTwo.rounds.reduce(
          (a, { score }) => a + score,
          0
        );
        return gameTwoRoundSum - gameOneRoundSum;
      });
      sortedGames = sortedGames.slice(0, 3);
      return { name: team.name, sortedGames, totalSum: 0 };
    });
    let sortedByTopScores = sortedGamesWithTeamName.sort((teamOne, teamTwo) => {
      let sumOfTopThreeGameOne = 0;
      let sumOfTopThreeGametwo = 0;
      teamOne.sortedGames.forEach((game) => {
        const gameSum = game.rounds.reduce((a, { score }) => a + score, 0);
        sumOfTopThreeGameOne += gameSum;
      });

      teamTwo.sortedGames.forEach((game) => {
        const gameSum = game.rounds.reduce((a, { score }) => a + score, 0);
        sumOfTopThreeGametwo += gameSum;
      });

      return sumOfTopThreeGametwo - sumOfTopThreeGameOne;
    });
    sortedByTopScores = sortedByTopScores.slice(0, 5);
    sortedByTopScores = sortedByTopScores.map((obj) => {
      let totalSum = 0;
      obj.sortedGames.forEach((game) => {
        const gameSum = game.rounds.reduce((a, { score }) => a + score, 0);
        totalSum += gameSum;
      });
      return { ...obj, totalSum };
    });
    return sortedByTopScores;
  }
  getFillColor(index) {
    const colors = { 1: "gold", 2: "silver", 3: "#cd7f32" };
    return colors[index];
  }
}
