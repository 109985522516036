<div class="navbar" [ngClass]="{ 'navbar--visible': isActive }">
  <div class="navbar__contents">
    <div class="navbar__contents__close" (click)="onClose()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
        />
      </svg>
    </div>
    <div class="navbar__contents__links">
      <a
        routerLink="/pick-a-team"
        class="navbar__contents__links__link"
        routerLinkActive="navbar__contents__links__link--active"
        >Pick a Team</a
      >
      <a
        routerLinkActive="navbar__contents__links__link--active"
        routerLink="/home"
        class="navbar__contents__links__link"
        >Dashboard</a
      >
      <a
        routerLinkActive="navbar__contents__links__link--active"
        routerLink="/logout"
        class="navbar__contents__links__link"
        >Logout</a
      >
    </div>
  </div>
</div>
<div
  class="transparency-layer"
  [ngClass]="{ 'transparency-layer--visible': isActive }"
></div>
