import { UserService } from "./../user.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    email: new FormControl(""),
    password: new FormControl(""),
  });
  errorMessage;
  loading;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {}

  onSubmit() {
    this.loading = true;
    this.userService.login(this.loginForm.value).subscribe({
      next: (res: any) => {
        if (res.user.associatedTeam) {
          // redirect dashboard
          this.router.navigate(["/home"]);
        } else {
          // redirect team select
          this.router.navigate(["/pick-a-team"]);
        }
      },
      error: (error) => {
        this.errorMessage = error.error.error;
        let errorMesssageElement = document.querySelector(".error-message");
        if (errorMesssageElement) {
          errorMesssageElement.classList.remove("fade-out");
        }
        setTimeout(() => {
          errorMesssageElement = document.querySelector(".error-message");
          errorMesssageElement.classList.add("fade-out");
        }, 50);
      },
    });
  }
}
