<div class="best-scores">
  <h1 class="best-scores__title">Best Three Weeks</h1>
  <ng-container *ngIf="sortedTeams">
    <div class="best-scores__top-five">
      <div
        *ngFor="let team of sortedTeams; let rank = index"
        class="best-scores__top-five__team"
      >
        <div class="best-scores__top-five__team__rank">{{ rank + 1 }}</div>
        <div class="best-scores__top-five__team__name">
          {{ team.name }}
          <hr />
          <div class="crown" *ngIf="rank >= 0 && rank <= 2">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              [attr.fill]="getFillColor(rank + 1)"
              width="40px"
              height="40px"
              viewBox="0 0 167.548 167.548"
              style="enable-background: new 0 0 167.548 167.548;"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M166.472,52.869c-0.954-0.707-2.23-0.717-3.19-0.042l-43.458,30.966c-1.013,0.717-1.397,2.024-0.949,3.174
		c0.416,1.076,0.622,2.146,0.622,3.207c0,4.841-3.934,8.774-8.775,8.774c-4.835,0-8.774-3.934-8.774-8.774
		c0-4.631,3.649-8.459,8.3-8.712c0.896-0.053,1.709-0.538,2.168-1.308c0.459-0.775,0.512-1.728,0.121-2.542L86.207,22.102
		c-0.896-1.896-3.986-1.896-4.883,0L54.979,77.657c-0.372,0.788-0.335,1.727,0.103,2.497c0.438,0.774,1.226,1.266,2.106,1.35
		c4.557,0.391,7.987,4.135,7.987,8.67c0,4.841-3.937,8.774-8.775,8.774s-8.775-3.934-8.775-8.774c0-0.865,0.166-1.783,0.511-2.816
		c0.372-1.107-0.01-2.347-0.959-3.032L4.298,52.843c-0.955-0.696-2.257-0.696-3.201,0.011c-0.949,0.701-1.334,1.933-0.949,3.051
		l30.958,89.149c0.377,1.081,1.402,1.814,2.552,1.814h100.219c1.154,0,2.167-0.733,2.558-1.814l30.955-89.149
		C167.79,54.812,167.41,53.578,166.472,52.869z M44.637,131.633h-16.2c-1.49,0-2.7-1.213-2.7-2.699c0-1.487,1.21-2.7,2.7-2.7h16.2
		c1.49,0,2.7,1.213,2.7,2.7C47.337,130.426,46.127,131.633,44.637,131.633z M77.037,131.633h-16.2c-1.49,0-2.7-1.213-2.7-2.699
		c0-1.487,1.21-2.7,2.7-2.7h16.2c1.49,0,2.7,1.213,2.7,2.7C79.737,130.426,78.527,131.633,77.037,131.633z M86.229,49.613
		L74.941,73.401c4.168,4.572,6.449,10.46,6.449,16.772c0,1.486-1.21,2.699-2.7,2.699c-1.493,0-2.7-1.213-2.7-2.699
		c0-5.495-2.183-10.547-6.146-14.257c-0.852-0.794-1.097-2.067-0.593-3.127L81.34,47.292c0.644-1.35,2.275-1.912,3.597-1.287
		C86.292,46.66,86.864,48.268,86.229,49.613z M109.439,131.633h-16.2c-1.492,0-2.699-1.213-2.699-2.699c0-1.487,1.207-2.7,2.699-2.7
		h16.2c1.487,0,2.7,1.213,2.7,2.7C112.14,130.426,110.927,131.633,109.439,131.633z M139.14,131.633h-13.5
		c-1.492,0-2.7-1.213-2.7-2.699c0-1.487,1.208-2.7,2.7-2.7h13.5c1.487,0,2.7,1.213,2.7,2.7
		C141.84,130.426,140.627,131.633,139.14,131.633z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
        </div>
        <div class="best-scores__top-five__team__score">
          {{ team.totalSum }}
        </div>
      </div>
    </div>
  </ng-container>
  <hr class="best-scores__footer-line" />
</div>
