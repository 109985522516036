<div class="login-container">
  <form class="login-form" (ngSubmit)="onSubmit()" [formGroup]="loginForm">
    <h1>Login to Quizzo</h1>

    <input type="email" formControlName="email" placeholder="Email address" />

    <input type="password" formControlName="password" placeholder="Password" />
    <h5 *ngIf="errorMessage" class="error-message">{{ errorMessage }}</h5>
    <a class="register-link" routerLink="/register">Click here to register</a>
    <button type="submit" class="button">
      <ng-container *ngIf="!loading">
        Login
      </ng-container>
      <ng-container #loader>
        <div class="lds-ring" *ngIf="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ng-container>
    </button>
  </form>
</div>
