import { GameScoreComponent } from "./../game-score/game-score.component";
import { UserService } from "./../user.service";
import { QuizzoService } from "./../quizzo.service";
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { withLatestFrom, map } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  // teams$;
  // currentUser$;
  combined$;
  teamLoading = false;
  @ViewChildren(GameScoreComponent) gameScore: QueryList<GameScoreComponent>;

  constructor(
    private quizzoService: QuizzoService,
    private userService: UserService
  ) {}

  ngAfterViewInit() {
    this.gameScore.changes.subscribe((comp) => {});
  }
  ngOnInit(): void {
    // this.teams$ = this.quizzoService.getTeams();
    // this.currentUser$ = this.userService.currentUser;
    let currentUser$;
    if (!!sessionStorage.getItem("currentUser")) {
      currentUser$ = of(JSON.parse(sessionStorage.getItem("currentUser")));
    } else {
      currentUser$ = this.userService.currentUser;
    }
    this.combined$ = this.quizzoService.getTeams().pipe(
      withLatestFrom(currentUser$),
      map(([teams, currentUser]) => {
        return { teams, currentUser };
      })
    );
  }

  getUserTeam(teams, associatedTeamId) {
    let userTeam;

    teams.forEach((team) => {
      if (team._id == associatedTeamId) {
        userTeam = team;
      }
    });
    return userTeam;
  }

  onSubmitTeamCard(event) {
    this.teamLoading = true;
    let { currentScores, currentTeamName, team } = event;
    currentScores = currentScores.map(({ score, isJoker }, index) => ({
      score,
      number: index + 1,
      isJoker,
    }));
    const cloneTeam = JSON.parse(JSON.stringify(team));
    let newGame = {
      date: new Date().toISOString(),
      rounds: [...currentScores],
    };
    console.log({ newGame });
    if (
      cloneTeam.games.some(
        (game) =>
          new Date(game.date).toDateString() ===
          new Date(newGame.date).toDateString()
      )
    ) {
      const foundGame = cloneTeam.games.find(
        (game) =>
          new Date(game.date).toDateString() ===
          new Date(newGame.date).toDateString()
      );
      const index = cloneTeam.games.indexOf(foundGame);

      newGame = { ...foundGame, ...newGame };
      cloneTeam.games[index] = newGame;
    } else {
      cloneTeam.games.push(newGame);
    }
    cloneTeam.name = currentTeamName;

    this.quizzoService.updateTeam(cloneTeam).subscribe((res) => {
      const gameScore: GameScoreComponent = this.gameScore.first;
      gameScore.onRefresh();
      this.teamLoading = false;
    });
  }
}
