<!-- <div *ngIf="teams; else loading" class="games-score card margin-small">
  <table class="games-score__team">
    <tr>
      <td *ngFor="let team of teams">
        {{ team.name }}
      </td>
    </tr>
    <tr>
      <td *ngFor="let team of teams">
        {{ team.games[0].rounds[0].score }}
      </td>
    </tr>
    <tr>
      <td *ngFor="let team of teams">
        {{ team.games[0].rounds[1].score }}
      </td>
    </tr>
    <tr>
      <td *ngFor="let team of teams">
        {{ team.games[0].rounds[2].score }}
      </td>
    </tr>
    <tr>
      <td *ngFor="let team of teams">
        {{ team.games[0].rounds[3].score }}
      </td>
    </tr>
  </table>
</div> -->

<div *ngIf="teams; else loading" class="games-score card margin-small">
  <div class="border-header">
    Team Scores
    <div class="refresh" (click)="onRefresh($event)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="white"
        viewBox="0 0 24 24"
      >
        <path
          d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"
        />
      </svg>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <div class="loading-container loading-container--mini">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      Loading...
    </div>
  </ng-container>
  <ng-container
    *ngIf="getTeamsWithTodaysGame(teams).length === 0 && !isLoading"
  >
    <h1>No one has posted any points yet!</h1>
  </ng-container>
  <div
    *ngFor="let team of getTeamsWithTodaysGame(teams); let rank = index"
    class="games-score__team"
  >
    <h1
      class="games-score__team__rank"
      [ngStyle]="{
        color: rank <= 2 ? 'white' : 'black',
        textShadow: rank > 2 ? 'none' : '1px 1px black',
        backgroundColor: getFillColor(rank + 1)
      }"
    >
      {{ rank + 1 }}
    </h1>
    <h1 class="games-score__team__name">{{ team.name }}</h1>
    <h1 class="games-score__team__game-total">
      {{ team.gameTotal }}
    </h1>
    <div class="games-score__team__round-container">
      <div
        *ngFor="let round of team.games[0].rounds; index as i"
        class="games-score__team__round-container__round"
      >
        <h1 class="games-score__team__round-container__round__number">
          {{ "Round " + (i + 1) }}
          <div
            *ngIf="round.isJoker"
            class="games-score__team__round-container__round__number__joker-container"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#0071b9"
                [attr.fill]="'gold'"
                stroke-width="2"
                d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828
          1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828
          8.332-1.151z"
              />
            </svg>
          </div>
        </h1>
        <h1 class="games-score__team__round-container__round__round-score">
          {{ round.score }}
        </h1>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading-container">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    Loading...
  </div>
</ng-template>
