import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-best-all-time",
  templateUrl: "./best-all-time.component.html",
  styleUrls: ["./best-all-time.component.scss"],
})
export class BestAllTimeComponent implements OnInit {
  @Input()
  teams;
  topFive;
  constructor() {}

  // getHighestRankingTeams(){
  //   const mappedTeams = this.teams.map(teams => {
  //     this.teams.
  //   })
  // }
  getTeamsWithoutZeroRounds(teams) {
    const isZero = ({ score }) => score == 0;
    const newTeams = JSON.parse(JSON.stringify(teams));

    newTeams.forEach((team) => {
      const teamGames = [...team.games];

      // game.rounds =
      const roundsWithoutZero = teamGames.filter(
        ({ rounds }) => !rounds.every(isZero)
      );
      team.games = roundsWithoutZero;
    });
    return newTeams;
  }
  getSumOfGames(team) {
    const games = team.games;

    const totals = [];
    //const totals game.rounds.reduce((a, { score }) => a + score, 0);
    games.forEach((game) => {
      const total = game.rounds.reduce((a, { score }) => a + score, 0);
      totals.push(total);
    });

    const allTotals = totals.reduce((a, value) => a + value, 0);

    return { allTotals, average: allTotals / totals.length, name: team.name };
  }

  ngOnInit(): void {
    const teamsWithoutZeros = this.getTeamsWithoutZeroRounds(this.teams);
    const averages = teamsWithoutZeros.map((team) => this.getSumOfGames(team));
    averages.sort((a, b) => b.average - a.average);

    const topFive = averages.slice(0, 5);
    this.topFive = topFive;
  }
  roundNumberDown(number) {
    return Math.round(number);
  }
  getFillColor(index) {
    const colors = { 1: "gold", 2: "silver", 3: "#cd7f32" };
    return colors[index];
  }
}
