import { AuthInterceptor } from "./auth.interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { TeamCardComponent } from "./team-card/team-card.component";
import { TeamCardPointEditComponent } from "./team-card-point-edit/team-card-point-edit.component";
import { HistoryComponent } from "./history/history.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { BestAllTimeComponent } from "./best-all-time/best-all-time.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ReactiveFormsModule } from "@angular/forms";
import { PickTeamComponent } from "./pick-team/pick-team.component";
import { TeamHeroCardComponent } from "./team-hero-card/team-hero-card.component";
import { TeamCardNameEditComponent } from "./team-card-name-edit/team-card-name-edit.component";
import { BestOfThreeComponent } from "./best-of-three/best-of-three.component";
import { MobileNavComponent } from "./mobile-nav/mobile-nav.component";
import { LogoutComponent } from "./logout/logout.component";
import { GameScoreComponent } from "./game-score/game-score.component";
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TeamCardComponent,
    TeamCardPointEditComponent,
    HistoryComponent,
    NavbarComponent,
    BestAllTimeComponent,
    LoginComponent,
    RegisterComponent,
    PickTeamComponent,
    TeamHeroCardComponent,
    TeamCardNameEditComponent,
    BestOfThreeComponent,
    MobileNavComponent,
    LogoutComponent,
    GameScoreComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
