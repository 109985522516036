import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-hero-card',
  templateUrl: './team-hero-card.component.html',
  styleUrls: ['./team-hero-card.component.scss']
})
export class TeamHeroCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
