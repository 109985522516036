import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { QuizzoService } from "../quizzo.service";

@Component({
  selector: "app-game-score",
  templateUrl: "./game-score.component.html",
  styleUrls: ["./game-score.component.scss"],
})
export class GameScoreComponent implements OnInit {
  id;
  isRefreshing;
  teams;
  teamsCopy;
  isLoading;
  refresh = new EventEmitter();
  constructor(private quizzoService: QuizzoService) {}

  ngOnInit(): void {
    this.quizzoService.getTeams().subscribe((teams) => {
      const teamsCopy = JSON.parse(JSON.stringify(teams));
      teamsCopy.forEach((team) => {
        team.games = this.getSortedGames(team.games);
      });
      this.teams = teamsCopy;
    });
  }

  public onRefresh(event = undefined) {
    this.isLoading = true;
    this.quizzoService.getTeams().subscribe((teams) => {
      const teamsCopy = JSON.parse(JSON.stringify(teams));
      teamsCopy.forEach((team) => {
        team.games = this.getSortedGames(team.games);
      });
      this.teams = teamsCopy;
      this.isLoading = false;
    });
    if (!event) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.id = setInterval(() => {
          this.quizzoService.getTeams().subscribe((teams) => {
            const teamsCopy = JSON.parse(JSON.stringify(teams));
            teamsCopy.forEach((team) => {
              team.games = this.getSortedGames(team.games);
            });
            this.teams = teamsCopy;
            this.isLoading = false;
          });
        }, 10000);
        setTimeout(() => {
          this.isRefreshing = false;
          clearInterval(this.id);
        }, 60000);
      }
    }
  }

  getSortedGames(games) {
    const sortedDate = games.sort((a: any, b: any) => {
      return +new Date(b.date) - +new Date(a.date);
    });
    return sortedDate;
  }

  teamHasGameForToday({ date }) {
    return new Date(date).toDateString() === new Date().toDateString();
  }
  getTeamsWithTodaysGame(teams) {
    return teams
      .filter((team) => this.teamHasGameForToday(team.games[0]))
      .sort((teamA, teamB) => {
        const teamATotal = teamA.games[0].rounds.reduce(
          (a, { score }) => a + score,
          0
        );
        teamA.gameTotal = teamATotal;
        const teamBTotal = teamB.games[0].rounds.reduce(
          (a, { score }) => a + score,
          0
        );
        teamB.gameTotal = teamBTotal;

        return teamBTotal - teamATotal;
      });
  }
  getSumOfGames(team) {
    const games = team.games;

    const totals = [];
    //const totals game.rounds.reduce((a, { score }) => a + score, 0);
    games.forEach((game) => {
      const total = game.rounds.reduce((a, { score }) => a + score, 0);
      totals.push(total);
    });

    const allTotals = totals.reduce((a, value) => a + value, 0);

    return { allTotals, average: allTotals / totals.length, name: team.name };
  }
  getFillColor(index) {
    const colors = { 1: "gold", 2: "silver", 3: "#cd7f32" };
    return colors[index];
  }
}
