import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap, shareReplay } from "rxjs/operators";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UserService {
  registrationEndpoint =
    "https://sjbxhj5fdl.execute-api.us-east-1.amazonaws.com/dev/users";
  loginEndpoint =
    "https://sjbxhj5fdl.execute-api.us-east-1.amazonaws.com/dev/users/login";

  updateEnpoint =
    "https://sjbxhj5fdl.execute-api.us-east-1.amazonaws.com/dev/users/updateUserTeam";
  user = new Subject<any>();

  // grab url and share with subscribers
  public currentUser = this.user.pipe(shareReplay(1));
  constructor(private http: HttpClient) {}
  register(registration: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) {
    return this.http.post(this.registrationEndpoint, registration);
  }

  login(login: { email: string; password: string }) {
    return this.http.post(this.loginEndpoint, login).pipe(
      tap((res) => {
        const { user, token } = res as any;
        sessionStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("currentUser", JSON.stringify(user));
        sessionStorage.setItem("token", token);
        this.currentUser.subscribe();
        this.setUser(user);
      })
    );
  }

  updateUser(update: { userId: string; teamId: string }) {
    return this.http.post(this.updateEnpoint, update).pipe(
      tap((user) => {
        this.setUser(user);
      })
    );
  }
  setUser(user) {
    sessionStorage.setItem("currentUser", JSON.stringify(user));

    this.user.next(user);
  }
}
