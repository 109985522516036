<app-navbar></app-navbar>
<div *ngIf="currentUser$ | async as user; else loading" class="container">
  <div class="title">
    <h1>Welcome, {{ user.firstName }}</h1>
    <h5>Please pick your team</h5>
  </div>

  <div *ngIf="teamNames$ | async as teams; else loading" class="pick-a-team">
    <div
      class="pick-a-team__card"
      *ngFor="let team of teams"
      (click)="onPickTeam({ teamId: team.id, userId: user._id })"
    >
      <h1>{{ team.name }}</h1>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading-container">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    Loading...
  </div>
</ng-template>
