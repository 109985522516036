<div class="register-container">
  <form
    class="register-form"
    (ngSubmit)="onSubmit()"
    [formGroup]="registerForm"
  >
    <h1>Sign Up For Quizzo</h1>

    <input type="text" formControlName="firstName" placeholder="First name" />

    <input type="text" formControlName="lastName" placeholder="Last name" />
    <input type="email" formControlName="email" placeholder="Email address" />

    <input type="password" formControlName="password" placeholder="Password" />

    <button type="submit" class="button">
      <ng-container *ngIf="!loading">
        Register
      </ng-container>
      <ng-container #loader>
        <div class="lds-ring" *ngIf="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ng-container>
    </button>
  </form>
</div>
