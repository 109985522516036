import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  @Input()
  team;

  constructor() {}

  ngOnInit(): void {}
  parseDate(date) {
    const parsedDate = new Date(date);
    return parsedDate.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
    });
  }
  getTotalPoints(game) {
    const total = game.rounds.reduce((a, { score }) => a + score, 0);
    return total;
  }
  onClickGame(id) {
    const element = document.querySelector(`#game-${id}`);
    element.classList.toggle("history__game__rounds-container--active");
  }

  getSortedGames(games) {
    const sortedDate = games.sort((a: any, b: any) => {
      return +new Date(b.date) - +new Date(a.date);
    });

    return sortedDate;
  }
}
