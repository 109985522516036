<div *ngIf="combined$ | async as combined; else loading">
  <app-navbar></app-navbar>

  <div class="dashboard">
    <div class="dashboard__current-game-history-container">
      <div class="current-game">
        <app-team-card
          [loading]="teamLoading"
          [team]="
            getUserTeam(combined.teams, combined.currentUser.associatedTeam)
          "
          (teamcardSubmitted)="onSubmitTeamCard($event)"
        ></app-team-card>
      </div>
      <div class="history">
        <app-history
          [team]="
            getUserTeam(combined.teams, combined.currentUser.associatedTeam)
          "
        ></app-history>
      </div>
    </div>
    <div class="dashboard__current-game-team-scores">
      <app-game-score></app-game-score>
    </div>
    <div class="best-scores">
      <div class="best-all-time">
        <app-best-all-time [teams]="combined.teams"></app-best-all-time>
      </div>
      <div class="best-three-weeks">
        <app-best-of-three [teams]="combined.teams"></app-best-of-three>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading-container">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    Loading...
  </div>
</ng-template>
