<div class="team-card">
  <ng-container *ngIf="team">
    <h1 class="team-card__title">
      <!-- {{ team.name }} -->
      <app-team-card-name-edit
        [value]="currentTeamName"
        [indexOfScore]="0"
        (nameEmitted)="onTeamNameChanged($event)"
      ></app-team-card-name-edit>
    </h1>
    <h1 class="team-card__date">Scorecard for {{ getDate() }}</h1>
    <div class="team-card__round-container">
      <div
        (mouseenter)="onMouseEnter(roundNumber)"
        (mouseleave)="onMouseLeave(roundNumber)"
        *ngFor="let score of currentTeamScores; let roundNumber = index"
        class="team-card__round-container__round"
      >
        <h3 class="team-card__round-container__round__number">
          Round {{ roundNumber + 1 }}
          <div
            [ngClass]="{
              'team-card__round-container__round__number__joker-container--active':
                isHoveredJokerState[roundNumber].isHovered,
              'team-card__round-container__round__number__joker-container--is-jokered':
                isHoveredJokerState[roundNumber].isJoker
            }"
            class="team-card__round-container__round__number__joker-container"
            (click)="onClickJoker(roundNumber)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#0071b9"
                [attr.fill]="
                  isHoveredJokerState[roundNumber].isJoker ? 'gold' : '#fff'
                "
                stroke-width="2"
                d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828
              1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828
              8.332-1.151z"
              />
            </svg>
          </div>
        </h3>
        <app-team-card-point-edit
          [value]="score.score"
          [indexOfScore]="roundNumber"
          (valueEmitted)="onValueEmitted($event)"
        ></app-team-card-point-edit>
      </div>
    </div>
    <div class="team-card__button-container">
      <div
        class="team-card__button-container__submit-button"
        (click)="onSubmitTeamCard()"
      >
        <ng-container *ngIf="!loading">
          Submit Scores
        </ng-container>
        <ng-container #loader>
          <div class="lds-ring" *ngIf="loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
