import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-team-card",
  templateUrl: "./team-card.component.html",
  styleUrls: ["./team-card.component.scss"],
})
export class TeamCardComponent implements OnInit {
  @Input()
  team;
  currentTeamScores = [
    { score: 0, isJoker: false },
    { score: 0, isJoker: false },
    { score: 0, isJoker: false },
    { score: 0, isJoker: false },
  ];
  current;
  @Output()
  teamcardSubmitted = new EventEmitter();
  currentTeamName;
  @Input()
  loading;

  isHoveredJokerState: { isHovered: boolean; isJoker: boolean }[] = [
    { isJoker: false, isHovered: false },
    { isJoker: false, isHovered: false },
    { isJoker: false, isHovered: false },
    { isJoker: false, isHovered: false },
  ];
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.currentTeamName = this.team.name;
    if (this.gameExistsForTodaysDate()) {
      const foundGame = this.team.games.find(
        (game) =>
          new Date(game.date).toDateString() === new Date().toDateString()
      );
      this.currentTeamScores = foundGame.rounds.map(({ score, isJoker }) => ({
        score,
        isJoker,
      }));
      this.currentTeamScores.forEach(({ isJoker }, index) => {
        this.isHoveredJokerState[index].isJoker = isJoker;
      });
    }
  }
  ngAfterViewInit(): void {
    // this.cd.detectChanges();
  }
  getDate() {
    return new Date().toLocaleString(undefined, {
      month: "long",
      day: "numeric",
    });
  }
  onValueEmitted({ score, index }) {
    this.currentTeamScores = this.currentTeamScores.map(
      (currentRound, currentIndex) => {
        return currentIndex == index
          ? { score: parseInt(score), isJoker: currentRound.isJoker }
          : { score: currentRound.score, isJoker: currentRound.isJoker };
      }
    );
  }

  onTeamNameChanged({ name }) {
    this.currentTeamName = name;
  }

  onSubmitTeamCard() {
    this.teamcardSubmitted.emit({
      team: this.team,
      currentScores: this.currentTeamScores,
      currentTeamName: this.currentTeamName,
    });
  }
  gameExistsForTodaysDate() {
    return this.team.games.some(
      (game) => new Date(game.date).toDateString() === new Date().toDateString()
    );
  }

  onMouseEnter(index) {
    this.isHoveredJokerState[index].isHovered = true;
  }
  onMouseLeave(index) {
    this.isHoveredJokerState[index].isHovered = false;
  }

  onClickJoker(index) {
    this.isHoveredJokerState[index].isJoker = !this.isHoveredJokerState[index]
      .isJoker;

    this.currentTeamScores[index].isJoker = !this.currentTeamScores[index]
      .isJoker;
    this.isHoveredJokerState.forEach((state, currentIndex) => {
      if (state.isJoker && currentIndex !== index) {
        state.isJoker = false;
        this.currentTeamScores[currentIndex].isJoker = false;
      }
    });
    console.log(this.currentTeamScores);
  }
}
