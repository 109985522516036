import { QuizzoService } from "./../quizzo.service";
import { UserService } from "./../user.service";
import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { of } from "rxjs";

@Component({
  selector: "app-pick-team",
  templateUrl: "./pick-team.component.html",
  styleUrls: ["./pick-team.component.scss"],
})
export class PickTeamComponent implements OnInit {
  teamNames$;
  currentUser$;
  constructor(
    private quizzoService: QuizzoService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.teamNames$ = this.quizzoService.getTeams().pipe(
      map((teams: any) => {
        return teams.map((team) => ({ name: team.name, id: team._id }));
      })
    );
    this.currentUser$ = of(JSON.parse(sessionStorage.getItem("currentUser")));
  }
  onPickTeam(ids: { userId: string; teamId: string }) {
    this.userService.updateUser(ids).subscribe((user) => {
      this.router.navigate(["/home"]);
    });
  }
}
